import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    PREMIUM_SUBSCRIPTION_MONTHLY_PRICE,
    PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_CROSSED,
    PREMIUM_SUBSCRIPTION_YEARLY_PRICE,
    PREMIUM_SUBSCRIPTION_YEARLY_PRICE_CROSSED,
} from '@app/common/src/constants/premium-subscription';
import { Wrapper, Value, Suffix } from './tariff-premium-price.styled';

type TariffPremiumPriceProps = {
    period: 'yearly' | 'monthly';
};

export const TariffPremiumPrice: React.FC<TariffPremiumPriceProps> = ({ period }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            {period === 'monthly' && <Value crossed>{PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_CROSSED}</Value>}
            {period === 'yearly' && <Value crossed>{PREMIUM_SUBSCRIPTION_YEARLY_PRICE_CROSSED}</Value>}
            <Value>
                {period === 'monthly' ? PREMIUM_SUBSCRIPTION_MONTHLY_PRICE : PREMIUM_SUBSCRIPTION_YEARLY_PRICE}
            </Value>
            <Suffix>&nbsp;/ {t(`blocks.pricing.common.${period}`)}</Suffix>
        </Wrapper>
    );
};
