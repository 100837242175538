import React from 'react';
import { useTranslation } from 'react-i18next';
import { TariffFeatures } from '../tariff-features';
import { Wrapper } from './tariff-features-premium.styled';

export const TariffFeaturesPremium: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <TariffFeatures
                data={[
                    t('blocks.pricing.premium.features.portfolios'),
                    t('blocks.pricing.premium.features.trades'),
                    t('blocks.pricing.premium.features.support'),
                    t('blocks.pricing.premium.features.dashboard'),
                    t('blocks.pricing.premium.features.importTrade'),
                    t('blocks.pricing.premium.features.taxReport'),
                    t('blocks.pricing.premium.features.dividends'),
                    t('blocks.pricing.premium.features.profitCompareWithIndex'),
                ]}
            />
        </Wrapper>
    );
};
