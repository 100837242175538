// export const PREMIUM_SUBSCRIPTION_MONTHLY_PRICE = '€9';
// export const PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_CROSSED = '€13';

// export const PREMIUM_SUBSCRIPTION_MONTHLY_YEARLY = '€90';
// export const PREMIUM_SUBSCRIPTION_MONTHLY_YEARLY_CROSSED = '€108';

export const PREMIUM_SUBSCRIPTION_MONTHLY_PRICE = '247 Kč';
export const PREMIUM_SUBSCRIPTION_MONTHLY_PRICE_CROSSED = '347 Kč';

export const PREMIUM_SUBSCRIPTION_YEARLY_PRICE = '2 470 Kč';
export const PREMIUM_SUBSCRIPTION_YEARLY_PRICE_CROSSED = '2 964 Kč';

export const UNLIMITED_SUBSCRIPTION_MONTHLY_PRICE = '347 Kč';
export const UNLIMITED_SUBSCRIPTION_MONTHLY_PRICE_CROSSED = '447 Kč';

export const UNLIMITED_SUBSCRIPTION_YEARLY_PRICE = '3 470 Kč';
export const UNLIMITED_SUBSCRIPTION_YEARLY_PRICE_CROSSED = '3 964 Kč';
